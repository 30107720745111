<template>
  <!-- <div> -->
  <v-text-field
    prefix="¥ "
    :class="className ? className : ''"
    v-model="displayValue"
    :placeholder="placeholder ? placeholder : ''"
    :rules="rules"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'CurrencyInput',
  data() {
    return {};
  },
  props: {
    value: [Number, String],
    className: String,
    placeholder: String,
    rules: [Object, Array],
  },
  computed: {
    displayValue: {
      get() {
        if (this.value) {
          return (
            this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
          );
        }
        return null;
      },
      set(modifiedValue) {
        let newValue = parseInt(modifiedValue.replace(/[^\d\\.]/g, ''));
        if (isNaN(newValue)) {
          newValue = null;
        }
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
