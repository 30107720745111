<template>
  <div v-if="contract">
    <contract-detail>
      <template v-slot:title>{{ $route.params.type }}契約</template>
      <template v-slot="{isEffective}">
        <v-btn class="t-btn--red-dark mt-3 mx-10" @click="initiateDelete" :disabled="!checkPerUser || !isEffective">
          契約解除
        </v-btn>
        <v-btn
          class="t-btn--red-dark mt-3 mx-10"
          v-if="contract.productTypeId === 5"
          @click="() => deleteContr({recreate: true})"
          :disabled="!checkPerUser || !isEffective"
        >
          組直しのため契約解除
        </v-btn>
      </template>
    </contract-detail>

    <v-dialog v-model="askRefundAmount" max-width="300px">

        <v-card>
          <v-card-title>
            <span class="dialog-headline">
              VM契約解除
            </span>
          </v-card-title>
          <v-card-text>
            <CurrencyInputNumber label="返却金額" v-model="refundAmount" />
          </v-card-text>
          <v-card-actions
            class="pb-5"
          >
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="#DB3069"
              class="white--text"
              @click="askRefundAmount = false"
            >
              {{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn
              depressed
              color="#004F8B"
              class="white--text"
              @click="deleteContr"
            >
              解除実行
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import contractDetail from '../../ContractDetail.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import CurrencyInputNumber from '../../../../../components/Input/CurrencyInputNumber.vue';
import getContractMixin from '../getContractMixin';

export default {
  name: 'ContractCancellation',
  mixins: [getContractMixin],
  components: { contractDetail, CurrencyInputNumber },
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      refundAmount: null,
      askRefundAmount: false
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions(['deleContractCanellation', 'contractAll']),
    initiateDelete() {
      if (this.contract.productTypeId === 5) {
        this.askRefundAmount = true;
      } else {
        this.deleteContr();
      }
    },
    deleteContr({recreate} = {}) {
      this.$confirm({
        title: '契約解除',
        message: `契約番号「${this.contractNumber}」の契約解除を実行します。よろしいですか？（実行後やり直しはできません）`,
        ok: async () => {
          var reason = 'Terminate';
          let refundAmount = null;
          if (this.contract.productTypeId === 5) {
            if (recreate) {
              reason = 'Recreate';
            } else {
              refundAmount = this.refundAmount;
            }
          }
          const changeData = {
            contractId: this.contractId,
            reason: reason,
            refundAmount,
          };

          await this.deleContractCanellation(changeData);
          this.$reloadRoute()
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
