import gql from "graphql-tag";

export default {
  data() {
    return {
      contract: null,
    };
  },
  computed: {
    contractId() {
      return parseInt(this.$route.params.id);
    },
    contractNumber() {
      return this.contract?.contractNumber
    }
  },
  apollo: {
    contract: {
      query: gql`query ($id: Int!) {
        contract(id: $id) {
          client { name }
          id
          contractNumber
          productTypeId
          productType {
            name
          }
        }
      }`,
      update: data => data.contract,
      skip() {return !this.contractId},
      variables() {
        return {
          id: this.contractId
        }
      }
    }
  }
};
